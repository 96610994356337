<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab id="odBrowseDepartmentsTab"
        class="od-browse-departments-tab"
        :to="{ name: 'Official Depository - Departments' }"
      >
      Departments
      </v-tab>
      <v-tab id="odBrowseForWhatTab"
        class="od-browse-for-what-tab"
        :to="{ name: 'Official Depository - For What' }"
      >
      For What
      </v-tab>
      <v-tab id="odBrowseToWhomTab"
        class="od-browse-to-whom-tab"
        :to="{ name: 'Official Depository - To Whom' }"
      >
      To Whom
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'OfficialDepositoryBrowse',
  components: {
  },
};
</script>
